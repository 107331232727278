import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import CreateRoomComponent from "../CreateRoomComponent";
import {Booking, Building, MeetingRoom, Room, RoomPlanUploadActionsInput} from "../../API";
import {getUniqueBuildings, SeatAndNeighborhood} from "../../Utils/Helpers";
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {
    deleteSecureRoom,
    deleteSecureSeatBooking,
    publishRoomAccessChange as publishRoomAccessChangeTemplate,
    updateSecureRoom,
    updateSecureSeatBooking
} from "../../graphql/mutations";
import {gql, useMutation, useQuery} from "@apollo/client";
import {getSeatBookings} from "../../graphql/queries";
import SeatManagerComponent from "../SeatManagerComponent";
import MeetingRoomManagerComponent from "../MeetingRoomManagerComponent";
import {useCognitoUserList} from "../../hooks/useCognitoUserList";
import RoomManagerTableComponent from "./RoomManagerTableComponent";
import RoomManagerUploadConfirmationDialog from "./RoomManagerUploadConfirmationDialog";
import RoomManagerUploadSvgButton from "./RoomManagerUploadSvgButton";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useErrorContext} from "../../hooks/useErrorContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useTranslation} from "react-i18next";
import NeighborhoodManagerComponent from "../NeighborhoodManagerComponent";
import Sit2GetherAddIcon from "../../styles/Sit2GetherAddIcon";
import RoomHintsManagerComponent from "./RoomHintsManagerComponent";
import {SelectItem} from "../../Utils/MeetingRoomBookingSelectorsUtil";
import GenericSelectorComponent from "../SelectorComponents/GenericSelectorComponent";
import {useNeighborhoodIdValidation} from "../../hooks/useNeighborhoodIdValidation";
import InfoDialog from "../InfoDialog";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";
import {RoomManagerUploadInconsistentDialog} from "./RoomManagerUploadInconsistentDialog";
import {SvgStructure, useRoomPlan} from "../../hooks/useRoomPlan";
import {useFileUpload} from "../../hooks/useFileUpload";

dayjs.extend(utc)

export interface RoomManagerProps {
    showRoomManager: boolean
    setShowRoomManager: (value: boolean) => void

}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const initialAlertStates = {
    success: false,
    error: false,
    error_create_room: false,
    no_upload_changes: false,
    error_execute_exec_struct: false
}

const HANDLEE_UPDATE_ROOM_IS_BOOKING_DISABLED = "updateRoomIsBookingDisabled";
const HANDLE_UPDATE_ROOM_IS_TIME_ACTIVE = "updateRoomIsTimeActive";
const HANDLE_UPDATE_ROOM_PLAN = "updateRoomPlan";


const RoomManagerComponent: React.FC<RoomManagerProps> = (props) => {
    const MAX_NEIGHBORHOOD_ID_LENGTH = 50;
    const {
        neighborhoodWithTooLongId,
        isNeighborhoodIdMaxLengthDialogOpen,
        setIsNeighborhoodIdMaxLengthDialogOpen,
    } = useNeighborhoodIdValidation(MAX_NEIGHBORHOOD_ID_LENGTH);
    const {showRoomManager, setShowRoomManager} = props
    const {
        currentUser,
        rooms,
        buildingList,
        managedBuildings,
    } = useMainApplicationContext()
    const {reportError} = useErrorContext();

    const cognitoUserList = useCognitoUserList(currentUser)
    const roomPlan = useRoomPlan()
    const fileUpload = useFileUpload()
    const permissionHelper = usePermissionHelper();
    const buildingsWithManagedNeighborhood = buildingList.filter(building => permissionHelper.hasManagedNeighborhoodInBuilding(building)) || [];
    const allowedBuildingsWithDuplicates = managedBuildings.concat(buildingsWithManagedNeighborhood);
    const allowedBuildings = getUniqueBuildings(allowedBuildingsWithDuplicates);
    const [roomToBeUpdatedAccordingToTimeFeature, setRoomToBeUpdatedAccordingToTimeFeature] = useState<Room>();
    const [multipleBookingsPerSeat, setMultipleBookingsPerSeat] = useState<Booking[]>([])
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const [showCreateRoom, setShowCreateRoom] = useState(false)
    const [isShowAlerts, setIsShowAlerts] = useState(initialAlertStates);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [onDeleteLoading, setOnDeleteLoading] = useState(false)
    const [onUploadLoading, setOnUploadLoading] = useState(false)
    const [showSeatManagerDialog, setShowSeatManagerDialog] = useState(false)
    const [showNeighborhoodManagerDialog, setShowNeighborhoodDialog] = useState(false)
    const [showRoomHintsConfig, setShowRoomHintsConfig] = useState(false)
    const [showMeetingRoomManagerDialog, setShowMeetingRoomManagerDialog] = useState(false)
    const [showUploadConfirmationDialog, setShowUploadConfirmationDialog] = useState(false)
    const [showUploadInconsistentDialog, setShowUploadInconsistentDialog] = useState(false)
    const [nonExistingNeighborhoodIds, setNonExistingNeighborhoodIds] = useState<string[]>([])
    const [isLoadingBookingsToDelete, setIsLoadingBookingsToDelete] = useState<boolean>(false)
    const [isShowingDisableTimeBookingConfirmationDialog, setIsShowingDisableTimeBookingConfirmationDialog] = useState(false);
    const [seatIdsToDelete, setSeatIdsToDelete] = useState<SeatAndNeighborhood[]>([]);
    const [seatsToUpdate, setSeatsToUpdate] = useState<SeatAndNeighborhood[]>([]);
    const [meetingRoomIdsToDelete, setMeetingRoomIdsToDelete] = useState<string[]>([]);
    const [meetingRoomsToUpdate, setMeetingRoomsToUpdate] = useState<MeetingRoom[]>([]);
    const [selectedBuilding, setSelectedBuilding] = useState<Building>(allowedBuildings[0]);
    const [isSVGUploadAbortedDialogOpen, setIsSVGUploadAbortedDialogOpen] = useState(false);
    const [roomPlanExecutionStructure, setRoomPlanExecutionStructure] = useState<RoomPlanUploadActionsInput>();
    const [roomPlanSvgStructure, setRoomPlanSvgStructure] = useState<SvgStructure>();
    const [roomPlanFile, setRoomPlanFile] = useState<File>();
    const [isUploadTrigerred, setIsUploadTrigerred] = useState<boolean>(false);

    useEffect(() => {
        if (!selectedBuilding) {
            setSelectedBuilding(allowedBuildings[0])
        }
    }, [allowedBuildings]);

    useEffect(() => {
        if (isUploadTrigerred) {
            setIsUploadTrigerred(false);
            triggerUploadRoomPlan();
        }
    }, [isUploadTrigerred])

    // Helper state variable to fire upload of room plan when changed
    const [updateRoomMutation] = useMutation(gql(updateSecureRoom))
    const [deleteRoomMutation] = useMutation(gql(deleteSecureRoom))
    const [deleteBookingMutation] = useMutation(gql(deleteSecureSeatBooking))
    const [updateBookingMutation] = useMutation(gql(updateSecureSeatBooking))
    const [publishRoomAccessChange] = useMutation(gql(publishRoomAccessChangeTemplate))
    const {refetch: refetchBookingsByRoom} = useQuery(gql(getSeatBookings), {
        skip: !selectedRoom || !selectedRoom.roomId
    })
    const {refetch: getBookingsWhenTimeIsActive} = useQuery(gql(getSeatBookings));//TODO: useBookingList
    const {refetch: getBookingsOfRoomPlan} = useQuery(gql(getSeatBookings), {
        skip: !roomToBeUpdatedAccordingToTimeFeature
    })
    const bookingsByRoom = 'bookingsByRoom';

    const deleteStartIcon = onDeleteLoading ? <CircularProgress style={{color: "white"}} size={25}/> : <DeleteIcon/>
    const uploadStartIcon = onUploadLoading ? <CircularProgress style={{color: "white"}} size={25}/> :
        <CloudUploadIcon/>

    const onCloseUploadConfirmationDialog = () => {
        setShowUploadConfirmationDialog(false)
    }

    const onCloseUploadInconsistenceDialog = () => {
        setShowUploadInconsistentDialog(false)
    }

    const showSuccess = () => setShowAlert("success");
    const showNoUploadChanges = () => setShowAlert("no_upload_changes");

    const handleErrorInUploadProcess = (prefix: string, error: Error, errorType: string = "error") => {
        setOnUploadLoading(false)
        setShowAlert(errorType)
        console.error(prefix, error)
    }


    const setShowAlert = (alertKey: string) => {
        setIsShowAlerts({...initialAlertStates, [alertKey]: true});
        setTimeout(() => {
            setIsShowAlerts(initialAlertStates)
        }, 4000)
    }

    const handleUpdateOrgUnitIdForBookings = (roomId: string, newOrgUnitId: string) => {
        refetchBookingsByRoom({input: {roomId: roomId, typeOfQuery: bookingsByRoom}})
            .then((result) => result?.data?.getSeatBookings?.items?.forEach((booking: Booking) => updateBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        date: booking.date,
                        orgUnitId: newOrgUnitId,
                        orgUnitAdmin: "Admin-" + newOrgUnitId,
                    }
                }
            })))
            .then(() => publishRoomAccessChange({variables: {roomId: roomId}}))
            .catch((err) => reportError(err, "", "RoomManagerComponent handleUpdateOrgUnitIdForBookings"))
    }

    const handleDeleteRoom = () => {
        roomPlan.getExecutionStructure(selectedRoom!.roomId, {
            neighborhoods: [],
            seats: [],
            meetingRooms: [],
            hints: []
        })
            .then(execStruct => roomPlan.executeExecutionStructure(execStruct))
            .then(() => deleteRoomMutation({
                variables: {
                    input: {
                        roomId: selectedRoom!.roomId
                    }
                }
            }))
            .then(() => setIsShowDeleteDialog(false))
            .then(() => setSelectedRoom(undefined))
            .catch(err => reportError(err, "", "RoomManagerComponent handleDeleteRoom"))
    }

    const handleUploadRoomPlan = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = fileUpload.getFileContent(event)

        if (!file) {
            handleErrorInUploadProcess("RoomManagerComponent file not present", new Error("No file selected"))
            return
        }

        const fileString = await file?.text()
        const svgStructure = roomPlan.getSvgStructure(selectedBuilding.buildingId, selectedRoom!.roomId, fileString)
        console.log("svgStructure", svgStructure)

        const inconsistendItems = roomPlan.getInconsistentItems(svgStructure)

        console.log("inconsistendItems", inconsistendItems)

        if (inconsistendItems.length > 0) {
            setNonExistingNeighborhoodIds(inconsistendItems.map(item => item.neighborhoodId))
            setShowUploadInconsistentDialog(true)
            return
        }

        const executionStructure = await roomPlan.getExecutionStructure(selectedRoom!.roomId, svgStructure)
            .catch(err => handleErrorInUploadProcess("RoomManagerComponent getExecutionStructure", err));

        console.log("executionStructure", executionStructure)

        const isAnythingToUpdate = executionStructure ? roomPlan.isAnythingToUpdate(executionStructure) : false;
        const showConfirmation = executionStructure ? roomPlan.showConfirmation(executionStructure) : false;

        if (!!executionStructure && isAnythingToUpdate) {
            setPropertiesAfterSvgParse(svgStructure, executionStructure, file);

            if (showConfirmation) {
                setShowUploadConfirmationDialog(true)
            } else {
                setIsUploadTrigerred(true)
            }
        } else {
            const fileId = await fileUpload.uploadSvg(selectedRoom!.roomId, file);
            updateRoomMutation({
                variables: {
                    input: {
                        roomId: selectedRoom!.roomId,
                        roomPlanId: fileId,
                        roomPlanExisting: true,
                        hasMeetingRooms: selectedRoom!.hasMeetingRooms,
                        hasHints: selectedRoom!.hasHints,
                        hasNeighborhood: selectedRoom!.hasHints,
                        roomPlanScaleFactor: selectedRoom?.roomPlanScaleFactor ?? 100
                    },
                    updateType: HANDLE_UPDATE_ROOM_PLAN
                }
            }).catch(err => handleErrorInUploadProcess("RoomManagerComponent updateRoomMutation", err));
            showSuccess()
        }
    };

    const setPropertiesAfterSvgParse = (svgStructure: SvgStructure, executionStructure: RoomPlanUploadActionsInput, file: File) => {
        setMeetingRoomsToUpdate(executionStructure!.update!.meetingRooms!.map(x => mapItem(x)))
        setMeetingRoomIdsToDelete(executionStructure!.delete!.meetingRooms!.map(x => mapId(x, 'meetingRoomId')))
        setSeatsToUpdate(executionStructure!.update!.seats!.map(x => mapItem(x)))
        setSeatIdsToDelete(executionStructure!.delete!.seats!.map(x => mapSeatId(x)))
        setRoomPlanSvgStructure(svgStructure)
        setRoomPlanExecutionStructure(executionStructure)
        setRoomPlanFile(file)
    }

    const mapSeatId = (item: any): SeatAndNeighborhood => ({
        seatId: item.seatName,
        neighborhoodId: item.neighborhoodId
    })

    const mapId = (item: any, field: string) => item && item[field] ? item[field] : ""

    const mapItem = (item: any) => item ? item : undefined

    const handleUploadRoomPlanAfterConfirmation = async () => {
        console.log("executionStructure", roomPlanExecutionStructure)
        setOnUploadLoading(true)
        const roomPlanUpdatedResponse = await roomPlan.executeExecutionStructure(roomPlanExecutionStructure ?? {})
            .catch(err => handleErrorInUploadProcess("RoomManagerComponent executeExecutionStructure", err, "error_execute_exec_struct"));
        if (!!roomPlanUpdatedResponse) {
            const fileId = await fileUpload.uploadSvg(selectedRoom!.roomId, roomPlanFile);
            updateRoomMutation({
                variables: {
                    input: {
                        roomId: selectedRoom!.roomId,
                        roomPlanId: fileId,
                        roomPlanExisting: true,
                        hasMeetingRooms: roomPlanSvgStructure?.meetingRooms?.length ?? 0 > 0 ?? false,
                        hasHints: roomPlanSvgStructure?.hints?.length ?? 0 > 0 ?? false,
                        hasNeighborhood: roomPlanSvgStructure?.neighborhoods.length ?? 0 > 0 ?? false,
                        roomPlanScaleFactor: selectedRoom?.roomPlanScaleFactor ?? 100
                    },
                    updateType: HANDLE_UPDATE_ROOM_PLAN
                }
            }).catch(err => handleErrorInUploadProcess("RoomManagerComponent updateRoomMutation", err));
            setSelectedRoom(undefined)
            setOnUploadLoading(false)
            showSuccess()
        }
    }

    const triggerUploadRoomPlan = () => {
        setShowUploadConfirmationDialog(false)
        handleUploadRoomPlanAfterConfirmation()
    }

    const updateTimeDataTo00h00_23h59 = useCallback((bookings: Booking[]) => {
        bookings.forEach((booking: Booking) => {
            updateBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        roomId: booking.roomId,
                        date: booking.date,
                        timeBegin: dayjs(booking.date).hour(0).minute(0).utc().toISOString(),
                        timeEnd: dayjs(booking.date).hour(23).minute(59).utc().toISOString()
                    },
                }
            })
                .catch(e => reportError(e, "", "RoomManagerComponent updateTimeDataTo00h00_23h59"))

        })
    }, []);

    const handleDisableTimeBooking = useCallback((room: Room, timeActive: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room?.roomId,
                    isTimeActive: false,
                    orgUnitId: room!.orgUnitId,
                    orgUnitAdmin: room!.orgUnitAdmin
                },
                updateType: HANDLE_UPDATE_ROOM_IS_TIME_ACTIVE
            }
        })
            .then(() => publishRoomAccessChange({variables: {roomId: room?.roomId}}).then())
            .then(() => setIsShowingDisableTimeBookingConfirmationDialog(false))
            .catch((err) => reportError(err, "", "RoomManagerComponent handleDisableTimeBooking"))

        if (timeActive) handleChangeOfExistingTimeBookings(room.roomId)

        function handleChangeOfExistingTimeBookings(roomId: string) {
            getBookingsWhenTimeIsActive({input: {roomId: roomId, typeOfQuery: bookingsByRoom}})
                .then((res: any) => updateTimeDataTo00h00_23h59(res.data.getSeatBookings.items!))
                .then(() => publishRoomAccessChange({variables: {roomId: roomId}}))
                .catch((err) => reportError(err, "", "RoomManagerComponent"))
        }

    }, [updateRoomMutation, publishRoomAccessChange, reportError, getBookingsWhenTimeIsActive, updateTimeDataTo00h00_23h59])

    const fillMultipleBookingsPerSeatList = useCallback((roomId: string) => {
        let listOfMultipleBookingsOnSeat: Booking[] = [];
        getBookingsOfRoomPlan({input: {roomId: roomId, typeOfQuery: bookingsByRoom}})
            .then((result) => collectMultipleBookingsOnSeat(result.data.getSeatBookings.items))
            .catch((err) => reportError(err, "", "RoomManagerComponent fillMultipleBookingsPerSeatList"))
            .finally(handleFinally)

        function collectMultipleBookingsOnSeat(bookings: Booking[]) {
            let uniqueCombination: string[] = []
            let sortedBookings = makeArraySortedByBeginTime(bookings)

            sortedBookings.forEach((booking: Booking) => {

                const bookingCode = getBookingCode(booking);
                const isAnotherBookingExistsOnSeatOnTheSameDate = uniqueCombination.includes(bookingCode);

                (isAnotherBookingExistsOnSeatOnTheSameDate) ? listOfMultipleBookingsOnSeat.push(booking) : uniqueCombination.push(bookingCode)

            })

            function getBookingCode(booking: Booking): string {
                return booking.roomId + "_" + booking.seatId + "_" + booking.date
            }

            function makeArraySortedByBeginTime(unsortedBookingList: Booking[]): Booking[] {
                const arrayToSort = [...unsortedBookingList]; // can't sort the param directly because it's a readonly value
                return arrayToSort.sort((leftHand, rightHand) => {
                    const leftHandValue: number = leftHand.timeBegin == null ? Number.MAX_SAFE_INTEGER : Date.parse(leftHand.timeBegin)
                    const rightHandValue: number = rightHand.timeBegin == null ? Number.MAX_SAFE_INTEGER : Date.parse(rightHand.timeBegin)
                    return leftHandValue - rightHandValue
                })
            }
        }

        function handleFinally() {
            if (!isLoadingBookingsToDelete) return;

            const isThereMultipleBookingOnSeats = listOfMultipleBookingsOnSeat.length !== 0;
            if (isThereMultipleBookingOnSeats) {
                setIsShowingDisableTimeBookingConfirmationDialog(true)
            } else {
                handleDisableTimeBooking(roomToBeUpdatedAccordingToTimeFeature as Room, true)
                disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)
            }
            setIsLoadingBookingsToDelete(false)
            setMultipleBookingsPerSeat(listOfMultipleBookingsOnSeat)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBookingsOfRoomPlan, handleDisableTimeBooking, isLoadingBookingsToDelete, roomToBeUpdatedAccordingToTimeFeature, updateTimeDataTo00h00_23h59])

    useEffect(function fetchMultipleBookingsPerSeat() {
        if (isLoadingBookingsToDelete) fillMultipleBookingsPerSeatList(roomToBeUpdatedAccordingToTimeFeature?.roomId ?? "");
    }, [fillMultipleBookingsPerSeatList, isLoadingBookingsToDelete, roomToBeUpdatedAccordingToTimeFeature]);

    function getTimeBookingDialogText(): string {
        let bookingsCount = multipleBookingsPerSeat.length;
        let errorMsg = "";
        if (bookingsCount === 1) {
            errorMsg += (t("message_disable_time_booking"))
        } else {
            errorMsg += (t("message_disable_time_bookings")).replace("%number", String(bookingsCount))
        }
        return errorMsg
    }

    const handleIsTimeBox = (room: Room, didTimeBecomeActive: boolean) => {
        setRoomToBeUpdatedAccordingToTimeFeature(room)

        if (didTimeBecomeActive) {
            updateRoomMutation({
                variables: {
                    input: {
                        roomId: room.roomId,
                        isTimeActive: true,
                        orgUnitId: room!.orgUnitId,
                        orgUnitAdmin: room!.orgUnitAdmin
                    },
                    updateType: HANDLE_UPDATE_ROOM_IS_TIME_ACTIVE
                }
            }).catch((err) => reportError(err, "", "RoomManagerComponent, handleIsTimeBox"));
        } else {
            // this will fire a useEffect (fetchMultipleBookingsPerSeat) to check if there are multiple bookings on a seats
            // and this might lead to showing a confirmation dialog through (isShowingDisableTimeBookingConfirmationDialog)
            // since we needed a comment to explain the process, then it needs to be refactored
            setIsLoadingBookingsToDelete(true)
        }

        if (!room.isTimeActive) disableRoomWhileTransitioning(room, false)
    }

    const disableRoomWhileTransitioning = (room: Room, disableBooking: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isBookingDisabled: disableBooking,
                    orgUnitId: room!.orgUnitId,
                    orgUnitAdmin: room!.orgUnitAdmin
                },
                updateType: HANDLEE_UPDATE_ROOM_IS_BOOKING_DISABLED
            }
        })
            .then(() => publishRoomAccessChange({variables: {roomId: room.roomId}}))
            .catch((err) => reportError(err, "", "RoomManagerComponent, disableRoomWhileTransitioning"))
    }

    const handleOkButtonOfTimeBookingDialog = () => {
        handeDeleteMultipleBookingsPerSeat();
        setTimeout(() => handleDisableTimeBooking(roomToBeUpdatedAccordingToTimeFeature as Room, true), 500)
        disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)

        function handeDeleteMultipleBookingsPerSeat() {
            multipleBookingsPerSeat.forEach((booking) => {
                deleteBookingMutation({
                    variables: {
                        input: {
                            bookingId: booking.bookingId,
                            bookerId: booking.bookerId,
                            orgUnitId: booking.orgUnitId,
                            seatId: booking.seatId,
                            roomId: booking.roomId,
                            date: booking.date
                        }
                    }
                })
                    .then(() => removeDoubledBookingFromList(booking.bookingId))
                    .catch((err) => reportError(err, "", "RoomManagerComponent handeDeleteMultipleBookingsPerSeat"))
            })

            function removeDoubledBookingFromList(bookingId: string) {
                setMultipleBookingsPerSeat((prev) => prev.filter(booking => booking.bookingId !== bookingId));
            }
        }
    }

    const handleCancelButtonOfTimeBookingDialog = () => {
        setIsShowingDisableTimeBookingConfirmationDialog(false)
        disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)
    }

    const handleDeleteRoomPlan = async () => {
        roomPlan.getExecutionStructure(selectedRoom!.roomId, {
            neighborhoods: [],
            seats: [],
            meetingRooms: [],
            hints: []
        })
            .then(execStruct => {
                console.log("deleting svg exec structure", execStruct)
                roomPlan.executeExecutionStructure(execStruct);
            })
            .then(() => {
                updateRoomMutation({
                    variables: {
                        input: {
                            roomId: selectedRoom!.roomId,
                            roomPlanId: "",
                            roomPlanExisting: false,
                            hasMeetingRooms: false,
                            hasHints: false,
                            hasNeighborhood: false,
                            roomPlanScaleFactor: selectedRoom?.roomPlanScaleFactor ?? 100
                        },
                        updateType: HANDLE_UPDATE_ROOM_PLAN
                    }
                }).catch(err => reportError(err, "", "RoomManagerComponent handleDeleteRoomPlan"))
            })
            .catch(err => reportError(err, "", "RoomManagerComponent handleDeleteRoom"))
    };

    const handleCreateRoom = () => {
        setSelectedRoom(undefined)
        setShowCreateRoom(true)
    }

    function getIndex(selectedBuilding: Building) {
        if (selectedBuilding === undefined) return 0;
        return allowedBuildings.findIndex(building => building.buildingId === selectedBuilding.buildingId);
    }

    function getBuildingOptions(): SelectItem<Building>[] {
        const displayName: (item: Building) => string = (item: Building) => {
            return item.buildingName;
        };
        return allowedBuildings.map(building => {
            return {element: building, label: displayName(building)};
        });

    }

    const {t} = useTranslation();
    return (
        <Dialog fullWidth={true} maxWidth={"lg"} style={{width: "100%", flexGrow: 1}} open={showRoomManager}
                data-testid={"roomManager"}>
            {isShowAlerts.error && <Alert severity="error">{t('message_error_file_type')}</Alert>}
            {isShowAlerts.success && <Alert severity="success">{t('message_success_uploaded_file')}</Alert>}
            {isShowAlerts.error_create_room && <Alert severity="error">{t('message_error_create_room')} </Alert>}
            {isShowAlerts.no_upload_changes && <Alert severity="success">{t('message_no_upload_changes')} </Alert>}
            {isShowAlerts.error_execute_exec_struct && <Alert severity="error">{t('message_error_execute_exec_struct')} </Alert>}
            <DialogTitle>{t("room_management_dialog-title")}</DialogTitle>
            <InfoDialog
                open={isNeighborhoodIdMaxLengthDialogOpen}
                onClose={() => setIsNeighborhoodIdMaxLengthDialogOpen(false)}
                title={t('neighborhood_id_too_long_title')}
                content={t('neighborhood_id_too_long_text') + neighborhoodWithTooLongId}
            />
            <InfoDialog
                open={isSVGUploadAbortedDialogOpen}
                onClose={() => setIsSVGUploadAbortedDialogOpen(false)}
                title={t('svg_upload_aborted_title')}
                content={t('svg_upload_aborted_text')}
            />
            <ClickAwayListener onClickAway={() => {
                if (!showSeatManagerDialog && !showMeetingRoomManagerDialog) {
                    setSelectedRoom(undefined)
                }
            }}>
                <Box>
                    <DialogContent>
                        {currentUser.isAdmin || allowedBuildings.length > 0 || permissionHelper.hasAnyNeighborhoodManagement() ?
                            <GenericSelectorComponent disabled={allowedBuildings.length === 1}
                                                      labelText={t("room_plan_manage_building")}
                                                      chooseFrom={getBuildingOptions()}
                                                      selectedIndex={getIndex(selectedBuilding)}
                                                      setSelected={setSelectedBuilding}
                                                      style={{width: "100%", textAlign: "left"}}
                                                      isOutlined={true}
                                                      dataTestId="select-building"
                            />
                            :
                            <div data-testid={"org-unit-label"}>{t("daily_bookings_table_orgunit_column")}
                                <div
                                    data-testid={"org-unit-label-content"}>{allowedBuildings[0] && allowedBuildings[0].buildingName}</div>
                            </div>
                        }

                        {showCreateRoom &&
                            <CreateRoomComponent
                                selectedBuilding={selectedBuilding}
                                setIsShowAlerts={setIsShowAlerts}
                                roomToEdit={selectedRoom}
                                showCreateRoom={showCreateRoom}
                                setShowCreateRoom={setShowCreateRoom}
                                isAdmin={currentUser.isAdmin || currentUser.isOrgUnitAdmin}
                                handleUpdateOrgUnitIdForBookings={handleUpdateOrgUnitIdForBookings}
                            />}
                        {isShowDeleteDialog && <Dialog open={isShowDeleteDialog}>
                            <DialogContent>
                                {t("delete_room_dialog_content")}
                                <DialogActions>
                                    <Button variant={"contained"} color={"primary"} component={"span"}
                                            onClick={handleDeleteRoom}>
                                        {t("confirm_dialog_ok_button-text")}
                                    </Button>
                                    <Button variant={"contained"} color={"primary"} component={"span"}
                                            onClick={() => setIsShowDeleteDialog(false)}>
                                        {t("confirm_dialog_cancel_button-text")}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>}

                        {isShowingDisableTimeBookingConfirmationDialog &&
                            <Dialog open={isShowingDisableTimeBookingConfirmationDialog}>
                                <DialogContent data-testid={"dialogContent-changeIsTimed-test"}>
                                    <DialogContentText data-testid={"dialogContentText"}>
                                        {getTimeBookingDialogText()}
                                    </DialogContentText>
                                    <DialogActions>
                                        <Button variant={"contained"} color={"primary"} component={"span"}
                                                onClick={handleOkButtonOfTimeBookingDialog}>
                                            {t("confirm_dialog_ok_button-text")}
                                        </Button>
                                        <Button variant={"contained"} color={"primary"} component={"span"}
                                                onClick={handleCancelButtonOfTimeBookingDialog}>
                                            {t("confirm_dialog_cancel_button-text")}
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>}

                        {selectedRoom &&
                            <SeatManagerComponent room={selectedRoom}
                                                  showSeatManager={showSeatManagerDialog}
                                                  setShowSeatManager={setShowSeatManagerDialog}
                            />
                        }
                        {selectedRoom &&
                            <RoomHintsManagerComponent room={selectedRoom}
                                                       showRoomHintsConfig={showRoomHintsConfig}
                                                       setShowRoomHintsConfig={setShowRoomHintsConfig}
                                                       cognitoUserList={cognitoUserList.userList}
                                                       building={selectedBuilding}
                            />
                        }
                        {selectedRoom && showNeighborhoodManagerDialog &&
                            <NeighborhoodManagerComponent
                                showNeighborhoodManager={showNeighborhoodManagerDialog}
                                setShowNeighborhoodManager={setShowNeighborhoodDialog}
                                selectedRoom={selectedRoom}
                            />
                        }
                        {selectedRoom &&
                            <MeetingRoomManagerComponent
                                selectedRoom={selectedRoom}
                                showMeetingRoomManager={showMeetingRoomManagerDialog}
                                setShowMeetingRoomManager={setShowMeetingRoomManagerDialog}
                            />
                        }
                        <RoomManagerTableComponent
                            rooms={rooms}
                            selectedRoom={selectedRoom}
                            selectedBuilding={selectedBuilding}
                            setSelectedRoom={setSelectedRoom}
                            setShowSeatConfig={setShowSeatManagerDialog}
                            setShowRoomHintsConfig={setShowRoomHintsConfig}
                            setShowNeighborhoodConfig={setShowNeighborhoodDialog}
                            setShowMeetingRoomsConfig={setShowMeetingRoomManagerDialog}
                            handleIsTimeBox={handleIsTimeBox}
                        />
                    </DialogContent>
                    <Divider/>
                    {permissionHelper.hasManagementForBuilding(selectedBuilding) &&
                        <DialogActions style={{display: "flex", justifyContent: "center", alignItems: "stretch"}}>
                            <Button
                                variant={"contained"} color={"primary"} startIcon={<Sit2GetherAddIcon/>}
                                onClick={handleCreateRoom}
                                data-testid={"create-btn"}
                            >
                                {t("button_create_room-text")}
                            </Button>
                            <RoomManagerUploadSvgButton handleUploadRoomPlan={handleUploadRoomPlan}
                                                        selectedRoom={selectedRoom} uploadStartIcon={uploadStartIcon}/>
                            <Button variant={"contained"} color={"primary"} startIcon={deleteStartIcon}
                                    style={{wordBreak: "break-word"}}
                                    disabled={!selectedRoom?.roomPlanExisting}
                                    onClick={handleDeleteRoomPlan}
                                    data-testid={"btn-delete"}
                            >
                                {t("button_svg_delete-text")}
                            </Button>
                            <Button variant={"contained"} color={"primary"} startIcon={<Edit/>}
                                    style={{wordBreak: "break-word"}}
                                    disabled={!selectedRoom}
                                    onClick={() => setShowCreateRoom(true)}
                                    data-testid={"btn-edit-room"}
                            >
                                {t("inv_mngmnt_edit_button-text")}
                            </Button>
                            <Button variant={"contained"} color={"primary"} startIcon={<DeleteForeverIcon/>}
                                    style={{wordBreak: "break-word"}}
                                    disabled={!selectedRoom}
                                    onClick={() => setIsShowDeleteDialog(true)}
                                    data-testid={"btn-delete-room"}
                            >
                                {t("delete")}
                            </Button>
                        </DialogActions>}
                    <Divider/>

                    <DialogActions>
                        <Button
                            onClick={() => setShowRoomManager(false)}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"close-btn"}
                        >
                            {t("button_close_room_management_dialog-text")}
                        </Button>
                    </DialogActions>
                </Box>
            </ClickAwayListener>
            <RoomManagerUploadInconsistentDialog
                showDialog={showUploadInconsistentDialog}
                nonExistedNeighborhoodIds={nonExistingNeighborhoodIds}
                onCloseDialog={onCloseUploadInconsistenceDialog}>
            </RoomManagerUploadInconsistentDialog>
            <RoomManagerUploadConfirmationDialog
                showUploadConfirmationDialog={showUploadConfirmationDialog}
                onCloseUploadConfirmationDialog={onCloseUploadConfirmationDialog}
                onUploadLoading={onUploadLoading}
                seatIdsToDelete={seatIdsToDelete}
                seatsToUpdate={seatsToUpdate}
                meetingRoomIdsToDelete={meetingRoomIdsToDelete}
                meetingRoomsToUpdate={meetingRoomsToUpdate}
                triggerUploadRoomPlan={triggerUploadRoomPlan}
            ></RoomManagerUploadConfirmationDialog>
        </Dialog>
    )
}
export default RoomManagerComponent;

